import { ChatDomain } from "./chat-domain";

export type ChatErrorsStore = string | null;

export const onChatError = ChatDomain.createEvent<ChatErrorsStore>();
export const onChatErrorsReset = ChatDomain.createEvent();

const $chatChatErrorsStore = ChatDomain.createStore<ChatErrorsStore>(null)
  .on(onChatError, (_state, error) => error)
  .reset(onChatErrorsReset);

export const $chatError = $chatChatErrorsStore.map((error) => error);
