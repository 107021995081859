import { Messaging_Api_ChannelScopeEnum } from "../../../graphql-schema-types.generated";
import { ChatMessagingSubscription } from "../graphql/index.generated";

export enum ChatInitializedStore {
  NONE = "none",
  PENDING = "pending",
  DONE = "done",
}

export enum ChatMode {
  FULL = "Full",
  COMPACT = "Compact",
  HIDDEN = "Hidden",
  PREVIEW = "Preview",
}

export type EventType = NonNullable<
  NonNullable<
    NonNullable<ChatMessagingSubscription["messaging"]>["connect"]
  >["event"]
>;

export type UserType = NonNullable<
  NonNullable<EventType["presence"]>["users"]
>[0];

export type TypingEventType = NonNullable<EventType["typing"]>;

export type TypingType = {
  [channelID: string]: (TypingEventType & { time: number }) | undefined;
};

export type ChannelType = NonNullable<
  NonNullable<EventType["init"]>["channels"]
>[0];

export type CoreMessagingHistoryMutationVariables = {
  scope?: Messaging_Api_ChannelScopeEnum | null | undefined;
  channel?: string | null | undefined;
  limit?: number | null | undefined;
  forward?: boolean | null | undefined;
  refid?: string | null | undefined;
};

export type SavedLocalStoreMessage = {
  text?: string | null | undefined;
  scope?: Messaging_Api_ChannelScopeEnum | null | undefined;
  channel?: string | null | undefined;
  status: "loading" | "error" | "sent";
  timestamp: number;
};
