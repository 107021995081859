import { ChatDomain } from "./chat-domain";
import { ChannelType } from "./chat-types";

export type ChatChannelsStore = ChannelType[];

export const onChatChannelsChanged =
  ChatDomain.createEvent<ChatChannelsStore>();
export const onChatChannelsReset = ChatDomain.createEvent();

const $chatChannelsStore = ChatDomain.createStore<ChatChannelsStore>([])
  .on(onChatChannelsChanged, (_state, channels) => channels)
  .reset(onChatChannelsReset);

export const $chatChannels = $chatChannelsStore.map((channels) => channels);
