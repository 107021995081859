import { forward } from "effector";

import { ChatDomain } from "./chat-domain";
import { ChatMode } from "./chat-types";
import { onChatErrorsReset } from "./errors";
import { setPresenceAwayFx, setPresenceOnlineFx } from "./presense";
import { onSelecteChannelReset } from "./selected-channel";

export type ChatModeStore = ChatMode;

export const onModeUpdate = ChatDomain.createEvent<ChatModeStore>();
export const onSetModeHide = ChatDomain.createEvent();
export const onSetModePreview = ChatDomain.createEvent();
export const onSetModeCompact = ChatDomain.createEvent();
export const onModeReset = ChatDomain.createEvent();

const $chatModeStore = ChatDomain.createStore<ChatModeStore>(ChatMode.HIDDEN)
  .on(onModeUpdate, (_state, mode) => mode)
  .on(onSetModeHide, () => ChatMode.HIDDEN)
  .on(onSetModePreview, () => ChatMode.PREVIEW)
  .on(onSetModeCompact, () => ChatMode.COMPACT)
  .reset(onModeReset);

forward({
  from: onSetModeHide,
  to: [onSelecteChannelReset, onChatErrorsReset, setPresenceAwayFx],
});

forward({
  from: onSetModePreview,
  to: onSelecteChannelReset,
});

forward({
  from: [onSetModeCompact, onSetModePreview, onModeUpdate],
  to: setPresenceOnlineFx,
});

export const $chatMode = $chatModeStore.map((mode) => mode);
