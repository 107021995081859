import { forward, sample } from "effector";

import { Messaging_Api_ChannelScopeEnum } from "../../../graphql-schema-types.generated";
import getApolloClient from "../../../lib/init-apollo-client";
import {
  ChatMessagingHistoryDocument,
  NotificationsReadDocument,
} from "../graphql/index.generated";
import { $chatChannels, ChatChannelsStore } from "./channels";
import { ChatDomain } from "./chat-domain";
import { $chatHistory, ChatHistoryStore } from "./chat-history";

export type ChatSelectedChannelStore = string | null;

const markUnreadOnSelectedChannelFX = ChatDomain.createEffect(
  (
    args: { channel: string; scope: Messaging_Api_ChannelScopeEnum } | undefined
  ) => {
    if (args != null) {
      void getApolloClient().mutate({
        mutation: NotificationsReadDocument,
        variables: {
          channel: args.channel,
          scope: args.scope,
        },
      });
    }
  }
);

const fetchHistorynSelectedChannelFX = ChatDomain.createEffect(
  (channel: ChatSelectedChannelStore) => {
    if (channel != null) {
      void getApolloClient().mutate({
        mutation: ChatMessagingHistoryDocument,
        variables: {
          scope: Messaging_Api_ChannelScopeEnum.UserPropertyScope,
          channel,
          limit: 100,
          refid: undefined,
        },
        fetchPolicy: "no-cache",
      });
    }
  }
);

export const onChannelSelected =
  ChatDomain.createEvent<ChatSelectedChannelStore>();
export const onSelecteChannelReset = ChatDomain.createEvent();

const $chatSelectedChannelStore =
  ChatDomain.createStore<ChatSelectedChannelStore>(null)
    .on(onChannelSelected, (_state, channelId) => channelId)
    .reset(onSelecteChannelReset);

sample({
  clock: onChannelSelected,
  source: $chatChannels,
  fn: (sourceData: ChatChannelsStore, clockData: ChatSelectedChannelStore) => {
    if (clockData == null) {
      return undefined;
    }

    const channel = sourceData.find((chnl) => chnl.channel === clockData);

    if (
      channel == null ||
      Boolean(channel.last_message.seen) ||
      !Boolean(channel.last_message.channel)
    ) {
      return undefined;
    }

    return {
      channel: clockData,
      scope: channel.scope,
    };
  },
  target: markUnreadOnSelectedChannelFX,
});

forward({
  from: onChannelSelected,
  to: fetchHistorynSelectedChannelFX,
});

sample({
  clock: onChannelSelected,
  source: $chatHistory,
  fn: (sourceData: ChatHistoryStore, clockData: ChatSelectedChannelStore) => {
    if (clockData == null) {
      return null;
    }

    return sourceData[clockData] == null ? clockData : null;
  },
  target: fetchHistorynSelectedChannelFX,
});

export const $selectedChannel = $chatSelectedChannelStore.map(
  (channelId) => channelId
);
