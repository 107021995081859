import { Messaging_Api_PresenceEnum } from "../../../graphql-schema-types.generated";
import getApolloClient from "../../../lib/init-apollo-client";
import { ChatMessagingPresenceDocument } from "../graphql/index.generated";
import { ChatDomain } from "./chat-domain";
import { UserType } from "./chat-types";

export type ChatPresenceStore = readonly UserType[];

export const setPresenceAwayFx = ChatDomain.createEffect(() => {
  void getApolloClient().mutate({
    mutation: ChatMessagingPresenceDocument,
    variables: {
      presence: Messaging_Api_PresenceEnum.PresenceAway,
    },
  });
});

export const setPresenceOnlineFx = ChatDomain.createEffect(() => {
  void getApolloClient().mutate({
    mutation: ChatMessagingPresenceDocument,
    variables: {
      presence: Messaging_Api_PresenceEnum.PresenceOnline,
    },
  });
});

export const onPresenceUpdate = ChatDomain.createEvent<ChatPresenceStore>();
export const onPresenceReset = ChatDomain.createEvent();

const $chatPresenceStore = ChatDomain.createStore<ChatPresenceStore>([])
  .on(onPresenceUpdate, (_state, presence) => presence)
  .reset(onPresenceReset);

export const $chatPresence = $chatPresenceStore.map((presence) => presence);
