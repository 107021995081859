import { sample } from "effector";

import { ChatSubscriptionMessageFragment } from "../graphql/index.generated";
import { ChatDomain } from "./chat-domain";

export type ChatHistoryStore = {
  [channelId: string]: ChatSubscriptionMessageFragment[] | undefined;
};

export type NotificationsDeleteClock = {
  messagesId: string[];
  channel: string;
};

export const onChatHistoryUpdate = ChatDomain.createEvent<ChatHistoryStore>();
export const onChatNotificationsDelete =
  ChatDomain.createEvent<NotificationsDeleteClock>();
export const onMarkSeenViewedNotifications =
  ChatDomain.createEvent<NotificationsDeleteClock>();
export const onChatHistoryReset = ChatDomain.createEvent();

const $chatHistoryStore = ChatDomain.createStore<ChatHistoryStore>({})
  .on(onChatHistoryUpdate, (_state, chatHistory) => chatHistory)
  .reset(onChatHistoryReset);

sample({
  clock: onChatNotificationsDelete,
  source: $chatHistoryStore,
  fn: (sourceData, clockData) => {
    const newHistory = sourceData[clockData.channel]?.filter(
      (elem) => !clockData.messagesId.includes(elem.id)
    );

    if (newHistory != null) {
      return { ...sourceData, [clockData.channel]: newHistory };
    }

    return sourceData;
  },
  target: onChatHistoryUpdate,
});

sample({
  clock: onMarkSeenViewedNotifications,
  source: $chatHistoryStore,
  fn: (sourceData, clockData) => {
    const newHistory = sourceData[clockData.channel]?.map((msg) =>
      clockData.messagesId.includes(msg.id) ? { ...msg, seen: true } : msg
    );

    if (newHistory != null) {
      return { ...sourceData, [clockData.channel]: newHistory };
    }

    return sourceData;
  },
  target: onChatHistoryUpdate,
});

export const $chatHistory = $chatHistoryStore.map((chatHistory) => chatHistory);
